import React, { useEffect, useState } from 'react';
import { Image } from 'react-native';
import { Block, Text, theme } from 'galio-framework';

import { storeString, getString } from '../lib/storage';
import { Images, argonTheme } from '../constants';
import Button from './Button';
import Modal from './Modal';

export default () => {
  const [visible, setVisible] = useState(false);
  useEffect(() => {
    (async function () {
      const popupStartShown = await getString('popupStartShown');
      if (popupStartShown !== '1') {
        setVisible(true);
        await storeString('popupStartShown', '1');
      }
    })();
  }, []);
  return (
    <Modal animationType="slide" transparent={true} visible={visible}>
      <Block style={styles.content}>
        <Block row middle style={{ marginTop: theme.SIZES.BASE }}>
          <Block middle style={{ flex: 1, maxWidth: 300 }}>
            <Text style={styles.headerText}>
              Discover your next favourite live.
            </Text>
          </Block>
        </Block>
        <Block row middle style={{ marginTop: theme.SIZES.BASE }}>
          <Block middle style={{ flex: 1, maxWidth: 220 }}>
            <Text style={styles.bodyText}>Welcome to tgi.Live,</Text>
            <Text style={styles.bodyText}>
              we help make discovering interesting live-sellers for your
              shoppertainment effortless.
            </Text>
          </Block>
        </Block>
        <Block row middle>
          <Image style={styles.image} source={Images.PopupStart} />
        </Block>
        <Block row middle style={{ marginTop: theme.SIZES.BASE }}>
          <Button style={styles.buttons} onPress={() => setVisible(false)}>
            <Block row>
              <Text style={styles.textButtons}>Continue</Text>
            </Block>
          </Button>
        </Block>
      </Block>
    </Modal>
  );
};

const styles = {
  content: {
    padding: 0,
    justifyContent: 'center',
  },
  headerText: {
    color: argonTheme.COLORS.PRIMARY,
    fontWeight: '900',
    fontSize: 28,
    textAlign: 'center',
  },
  bodyText: {
    color: argonTheme.COLORS.DEFAULT,
    fontWeight: '900',
    fontSize: 16,
    textAlign: 'center',
  },
  image: {
    width: 300,
    height: 250,
  },
  buttons: {
    width: 175,
    height: 40,
    backgroundColor: '#fff',
    shadowColor: argonTheme.COLORS.BLACK,
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowRadius: 8,
    shadowOpacity: 0.1,
    elevation: 1,
    // outline: 'none',
  },
  textButtons: {
    color: argonTheme.COLORS.PRIMARY,
    fontWeight: '600',
    fontSize: 14,
  },
};

import { Platform } from 'react-native';

const config = Platform.select({
  web: {
    screens: {
      Root: {
        screens: {
          App: {
            screens: {
              HomeHomeStack: {
                screens: {
                  HomeStack: {
                    screens: {
                      Home: {
                        initialParams: { tabId: 'live' },
                        path: 'home/:tabId?/:tabSlug?',
                      },
                    },
                  },
                },
              },
              ExploreStack: {
                screens: {
                  Explore: 'explore',
                  Brand: 'explore/:fbPageId/:fbPageName?',
                },
              },
              Profile: {
                screens: {
                  Profile: 'profile',
                },
              },
              ContactUsStack: {
                screens: {
                  ContactUs: 'contact-us',
                },
              },
            },
          },
          Livestream: 'video/:id/:fbPageName?/:fbVideoName?',
        },
      },
    },
  },
  default: {
    screens: {
      Root: {
        screens: {
          App: {
            screens: {
              HomeHomeStack: {
                screens: {
                  HomeTabs: {
                    screens: {
                      HomeStack: {
                        screens: {
                          Home: {
                            initialParams: { tabId: 'live' },
                            path: 'home/:tabId?/:tabSlug?',
                          },
                        },
                      },
                      ExploreStack: {
                        screens: {
                          Explore: 'explore',
                          Brand: 'explore/:fbPageId/:fbPageName?',
                        },
                      },
                    },
                  },
                },
              },
              Profile: {
                screens: {
                  Profile: 'profile',
                },
              },
              ContactUsStack: {
                screens: {
                  ContactUs: 'contact-us',
                },
              },
            },
          },
          Livestream: 'video/:id/:fbPageName?/:fbVideoName?',
        },
      },
    },
  },
});
const linking = {
  prefixes: ['https://tgi.live', 'https://www.tgi.live', 'tgilive://'],
  config,
};

export default linking;

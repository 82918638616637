import React from 'react';
import {
  StyleSheet,
  ImageBackground,
  Dimensions,
  StatusBar,
  KeyboardAvoidingView,
  Alert,
  Platform,
  Image,
} from 'react-native';
import * as WebBrowser from 'expo-web-browser';
import { Block, Text, theme } from 'galio-framework';

import { useAmplifyAuthProvider } from '../lib/amplify';
import { Button, Icon } from '../components';
import { Images, argonTheme } from '../constants';
import { isMobile } from '../constants/utils';

const { width, height } = Dimensions.get('screen');

const Login = ({ navigation }) => {
  const authProvider = useAmplifyAuthProvider();
  return (
    <Block flex middle>
      <StatusBar hidden />
      <ImageBackground
        source={Images.LoginBackground}
        style={{ width, height, zIndex: 1 }}
      >
        <Block flex middle>
          <Block style={styles.registerContainer}>
            <Block flex={1} middle style={styles.socialConnect}>
              <Image source={Images.LogoOnboarding} style={{ height: 300, width: 300 }} />
              {/* <Text color="#8898AA" size={12}> */}
              {/* <Text
                textStyle={{
                  color: argonTheme.COLORS.PRIMARY,
                  fontSize: 14,
                }}
              >
                Login with
              </Text> */}
              <Block row style={{ marginTop: theme.SIZES.BASE }}>
                <Button
                  style={styles.socialButtons}
                  onPress={() => authProvider.login({ provider: 'Facebook' })}
                >
                  <Block row>
                    <Icon
                      name="logo-facebook"
                      family="Ionicon"
                      size={14}
                      color={'black'}
                      style={{ marginRight: 5 }}
                    />
                    <Text style={styles.socialTextButtons}>
                      Sign in with Facebook
                    </Text>
                  </Block>
                </Button>
              </Block>
              {Platform.OS === 'ios' && (
                <Block row style={{ marginTop: theme.SIZES.BASE }}>
                  <Button
                    style={styles.socialButtons}
                    onPress={() =>
                      authProvider.login({ provider: 'SignInWithApple' })
                    }
                  >
                    <Block row>
                      <Icon
                        name="logo-apple"
                        family="Ionicon"
                        size={14}
                        color={'black'}
                        style={{ marginRight: 5 }}
                      />
                      <Text style={styles.socialTextButtons}>
                        Sign in with Apple
                      </Text>
                    </Block>
                  </Button>
                </Block>
              )}
              {!isMobile() && (
                <Block row style={{ marginTop: theme.SIZES.BASE }}>
                  <Button
                    style={styles.socialButtons}
                    onPress={() =>
                      navigation.navigate('Home')
                    }
                  >
                    <Block row>
                      <Text style={styles.socialTextButtons}>
                        Continue as Guest
                      </Text>
                    </Block>
                  </Button>
                </Block>
              )}
              <Block flex={0.17} center style={{ marginTop: theme.SIZES.BASE }}>
                <KeyboardAvoidingView
                  style={{ flex: 1 }}
                  behavior="padding"
                  enabled
                >
                  <Block row center>
                    <Text color="#8898AA" size={12} style={{ marginBottom: 3 }}>
                      By signing in, I agree to the:
                    </Text>
                  </Block>
                  <Block row center>
                    <Button
                      style={{ width: 100, height: 18, margin: 0 }}
                      shadowless
                      color="transparent"
                      textStyle={{
                        color: '#8898AA',
                        fontSize: 12,
                      }}
                      onPress={async () => {
                        await WebBrowser.openBrowserAsync(
                          'https://www.pretepop.com/pages/privacy-policy'
                        );
                      }}
                    >
                      Privacy Policy
                    </Button>
                  </Block>
                  <Block row center>
                    <Button
                      style={{ width: 100, height: 18, margin: 0 }}
                      shadowless
                      color="transparent"
                      textStyle={{
                        color: '#8898AA',
                        fontSize: 12,
                      }}
                      onPress={async () => {
                        await WebBrowser.openBrowserAsync(
                          'https://www.pretepop.com/pages/terms-of-use'
                        );
                      }}
                    >
                      Terms of Use
                    </Button>
                  </Block>
                </KeyboardAvoidingView>
              </Block>
            </Block>
          </Block>
        </Block>
      </ImageBackground>
    </Block>
  );
};

const styles = {
  registerContainer: {
    width: width * 0.9,
    height: height * 0.78,
    backgroundColor: 'rgba(244, 245, 247, 0.6)',
    borderRadius: 4,
    shadowColor: argonTheme.COLORS.BLACK,
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowRadius: 8,
    shadowOpacity: 0.1,
    elevation: 1,
    overflow: 'hidden',
  },
  socialConnect: {
    // backgroundColor: argonTheme.COLORS.WHITE,
    backgroundColor: 'rgba(255, 255, 255, 0.6)',
    borderBottomWidth: StyleSheet.hairlineWidth,
    borderColor: '#8898AA',
  },
  socialButtons: {
    width: 175,
    height: 40,
    backgroundColor: '#fff',
    shadowColor: argonTheme.COLORS.BLACK,
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowRadius: 8,
    shadowOpacity: 0.1,
    elevation: 1,
  },
  socialTextButtons: {
    color: argonTheme.COLORS.PRIMARY,
    fontWeight: '600',
    fontSize: 12,
  },
  inputIcons: {
    marginRight: 12,
  },
  passwordCheck: {
    paddingLeft: 15,
    paddingTop: 13,
    paddingBottom: 30,
  },
  createButton: {
    width: width * 0.5,
    marginTop: 25,
  },
};

export default Login;

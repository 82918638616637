import React, { useLayoutEffect } from 'react';
import {
  StyleSheet,
  Dimensions,
  SafeAreaView,
  FlatList,
  Platform,
  Image,
} from 'react-native';
import { Block, Text, theme } from 'galio-framework';

import { Header, Thumbnail } from '../components';
import { useFbPage, useFbVideos } from '../hooks/useGraphQL';
import { useDescription } from '../hooks/html';
// import articles from '../constants/articles';
import { HeaderHeight, isMobile } from '../constants/utils';
import title from '../lib/title';
const { width } = Dimensions.get('window');

const MemoizedCard = React.memo(Thumbnail);

const Brand = ({ navigation, route }) => {
  const fbPageId = route.params?.fbPageId;

  const { data: fbPage } = useFbPage({ id: fbPageId });
  const { data, loading, loaded, fetch, refetch } = useFbVideos({ fbPageId });

  useLayoutEffect(() => {
    if (fbPage?.name) {
      navigation.setOptions({
        title: title(fbPage.name),
        header: ({ navigation, scene }) => (
          <Header
            title={title(fbPage.name)}
            back
            backTo="/explore"
            navigation={navigation}
            scene={scene}
          />
        ),
      });
    }
  }, [navigation, fbPage]);

  useDescription(fbPage?.description);

  const rowData = [...Array(Math.ceil(data.length / 2)).keys()].map((index) => [
    data[index * 2],
    index * 2 + 1 < data.length ? data[index * 2 + 1] : undefined,
  ]);

  const renderItem = ({ item: row }) => (
    <Block flex row middle>
      {row.map((item, index) => (
        <MemoizedCard
          key={item?.id || 'blank'}
          item={item}
          style={
            index === 1
              ? { marginLeft: theme.SIZES.BASE, marginRight: theme.SIZES.BASE }
              : { marginLeft: theme.SIZES.BASE }
          }
        />
      ))}
    </Block>
  );

  const onEndReached = () => {
    fetch();
  };

  const onRefresh = () => {
    refetch();
  };

  const renderArticles = () => {
    return (
      <SafeAreaView style={styles.articles}>
        <Block flex>
          <Block flex row middle style={styles.details}>
            <Block row flex style={styles.cardContainer}>
              <Block style={styles.cardImageContainer}>
                <Image
                  source={{ uri: fbPage?.image }}
                  style={styles.cardImage}
                />
              </Block>
              <Block flex space="between" style={styles.cardDescription}>
                <Text size={14} style={styles.cardTitle}>
                  {fbPage?.description}
                </Text>
              </Block>
            </Block>
          </Block>
          {loaded && !data.length && (
            <Block flex center style={{ marginTop: 20, marginBottom: 20 }}>
              <Text>No videos found!</Text>
            </Block>
          )}
          <FlatList
            style={{ flex: 1 }}
            data={rowData}
            renderItem={renderItem}
            keyExtractor={(row) =>
              `${row[0] && row[0].id}#${row[1] && row[1].id}`
            }
            onEndReached={onEndReached}
            onRefresh={onRefresh}
            refreshing={loading}
            showsVerticalScrollIndicator={!isMobile()}
          />
        </Block>
      </SafeAreaView>
    );
  };

  return (
    <Block flex center style={styles.home}>
      {renderArticles()}
    </Block>
  );
};

const styles = StyleSheet.create({
  home: {
    // width: width,
    width: '100%',
    // marginTop: Platform.OS === 'android' ? -HeaderHeight : 0,
  },
  details: {
    flex: Platform.select({
      web: '0 1 auto',
      default: 0,
    }),
  },
  cardContainer: {
    maxWidth:
      width >= 680 ? 680 + theme.SIZES.BASE : width - theme.SIZES.BASE * 2,
  },
  cardImageContainer: {
    shadowColor: theme.COLORS.BLACK,
    shadowOffset: { width: 0, height: 2 },
    shadowRadius: 4,
    shadowOpacity: 0.1,
    elevation: 2,
  },
  cardImage: {
    height: 128,
    width: 128,
  },
  cardDescription: {
    padding: theme.SIZES.BASE / 2,
  },
  cardTitle: {
    flex: 1,
    flexWrap: 'wrap',
    paddingBottom: 6,
  },
  articles: {
    // width: width - theme.SIZES.BASE * 2,
    width: '100%',
    paddingVertical: theme.SIZES.BASE,
    flex: 1,
    maxHeight: '100%',
  },
});

export default Brand;

import React from 'react';
import {
  ImageBackground,
  Image,
  StyleSheet,
  StatusBar,
  Dimensions,
  Platform,
  View,
  TouchableOpacity,
  useWindowDimensions,
} from 'react-native';
import { Header } from 'react-native-elements';
import { Video } from 'expo-av';
import { Ionicons } from '@expo/vector-icons';
import { useLinkTo, useNavigationState } from '@react-navigation/native';
// import { WebView } from 'react-native-webview';
import { Block, Button, Text, theme } from 'galio-framework';
import Linking from '../lib/linking';

const { height, width } = Dimensions.get('window');
const iPhoneX = () =>
  Platform.OS === 'ios' &&
  (height === 812 || width === 812 || height === 896 || width === 896);

import { useFbVideo } from '../hooks/useGraphQL';
import { argonTheme } from '../constants';
import { HeaderHeight } from '../constants/utils';
import Icon from '../components/Icon';
import VideoPlayer from '../components/VideoPlayer';

export default ({ navigation, route }) => {
  const id = route.params?.id;

  const navStateIndex = useNavigationState((state) => state.index);
  const linkTo = useLinkTo();

  const { data, loading, loaded, fetch } = useFbVideo({ id });

  if (!loaded) return null;

  const item = data || {};
  const uri =
    item.status === 'live' && Platform.OS === 'ios'
      ? `https://www.facebook.com/video/playback/playlist.m3u8?v=${item.id}&a=0`
      : item.url;
  const hlsUri = `https://www.facebook.com/video/playback/playlist.m3u8?v=${item.id}&a=0`;
  const status = item.status;
  const live = status === 'live';
  const fbVideoUrl = `https://www.facebook.com/${item.fbPageId}/videos/${item.id}`;

  return (
    <Block flex style={styles.container}>
      <Block flex>
        <VideoPlayer
          style={styles.video}
          videoProps={{
            source: {
              uri,
              hlsUri,
              overrideFileExtensionAndroid: live ? 'mpd' : undefined,
            },
            rate: 1.0,
            volume: 1.0,
            isMuted: false,
            resizeMode: Video.RESIZE_MODE_CONTAIN,
            shouldPlay: true,
            isLooping: false,
          }}
          inFullscreen={true}
          showFullscreenButton={false}
          live={live}
        />
        {/* <Video
          style={styles.video}
          source={{
            uri,
            overrideFileExtensionAndroid: live ? 'mpd' : undefined,
          }}
          rate={1.0}
          volume={1.0}
          isMuted={false}
          resizeMode={Video.RESIZE_MODE_CONTAIN}
          shouldPlay
          isLooping={false}
        /> */}
        <Header
          leftComponent={
            live && (
              <View style={styles.live}>
                <Text size={12} color={argonTheme.COLORS.WHITE} bold>
                  LIVE
                </Text>
              </View>
            )
          }
          rightComponent={
            <TouchableOpacity
              style={styles.close}
              onPress={() =>
                navStateIndex === 0 ? linkTo('/home') : navigation.goBack()
              }
            >
              <Ionicons
                name="close"
                size={24}
                color={argonTheme.COLORS.WHITE}
              />
            </TouchableOpacity>
          }
          containerStyle={{
            backgroundColor: 'transparent',
            justifyContent: 'space-around',
            position: 'absolute',
            top: 0,
            right: 0,
            left: 0,
            borderBottomWidth: 0,
            paddingHorizontal: 0,
            paddingTop: 0,
            margin: 0,
            zIndex: 2,
          }}
          pointerEvents="box-none"
        />
        <View style={styles.bottom} pointerEvents="box-none">
          <Block row middle style={{ marginTop: theme.SIZES.BASE }}>
            <Button
              style={styles.buttons}
              onPress={() => Linking.openURL(fbVideoUrl)}
            >
              <Block row>
                <Text style={styles.textButtons}>Buy on Facebook</Text>
              </Block>
            </Button>
          </Block>
        </View>
        {/* <View style={styles.navbar}>
          {live && (
            <View style={styles.live}>
              <Text size={12} color={argonTheme.COLORS.WHITE} bold>
                LIVE
              </Text>
            </View>
          )}
          <TouchableOpacity
            style={styles.close}
            onPress={() =>
              navStateIndex === 0 ? linkTo('/home') : navigation.goBack()
            }
          >
            <Ionicons name="close" size={24} color={argonTheme.COLORS.WHITE} />
          </TouchableOpacity>
        </View> */}
        {/* <View>
          <Icon
            name="log-out"
            family="Galio"
            size={14}
            style={{
              position: 'absolute',
              left: 0,
              right: 0,
              top: 0,
              bottom: 0,
            }}
          />
        </View> */}
      </Block>
    </Block>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: theme.COLORS.BLACK,
  },
  video: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    width,
    height,
    zIndex: 1,
  },
  padded: {
    paddingHorizontal: theme.SIZES.BASE * 2,
    zIndex: 3,
    position: 'absolute',
    bottom:
      Platform.OS === 'android' ? theme.SIZES.BASE * 2 : theme.SIZES.BASE * 3,
  },
  button: {
    width: width - theme.SIZES.BASE * 4,
    height: theme.SIZES.BASE * 3,
    shadowRadius: 0,
    shadowOpacity: 0,
  },
  pro: {
    backgroundColor: argonTheme.COLORS.INFO,
    paddingHorizontal: 8,
    marginLeft: 3,
    borderRadius: 4,
    height: 22,
    marginTop: 15,
  },
  gradient: {
    zIndex: 1,
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    height: 66,
  },
  navbar: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    paddingVertical: 0,
    paddingBottom: theme.SIZES.BASE * 1.5,
    paddingTop: iPhoneX() ? theme.SIZES.BASE * 4 : theme.SIZES.BASE,
    zIndex: 5,
  },
  live: {
    backgroundColor: 'rgb(240, 40, 74)',
    borderRadius: 4,
    position: 'absolute',
    // top: iPhoneX() ? theme.SIZES.BASE * 4 : theme.SIZES.BASE,
    top: 0,
    // left: 0,
    left: theme.SIZES.BASE,
    paddingTop: 3,
    paddingRight: 6,
    paddingBottom: 5,
    paddingLeft: 6,
  },
  close: {
    position: 'absolute',
    // top: iPhoneX() ? theme.SIZES.BASE * 4 : theme.SIZES.BASE,
    top: 0,
    right: theme.SIZES.BASE,
  },
  bottom: {
    position: 'absolute',
    right: 0,
    bottom: 60,
    left: 0,
    textAlign: 'center',
    zIndex: 5,
  },
  buttons: {
    width: 175,
    height: 40,
    backgroundColor: 'rgba(245, 46, 78, 0.75)',
    shadowColor: argonTheme.COLORS.BLACK,
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowRadius: 8,
    shadowOpacity: 0.1,
    elevation: 1,
    // outline: 'none',
  },
  textButtons: {
    color: argonTheme.COLORS.WHITE,
    fontWeight: '600',
    fontSize: 14,
  },
});

/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-1",
    "aws_cognito_identity_pool_id": "ap-southeast-1:7910ad5c-dbcb-4f7a-ac07-5e618d1db841",
    "aws_cognito_region": "ap-southeast-1",
    "aws_user_pools_id": "ap-southeast-1_SBNgXddqd",
    "aws_user_pools_web_client_id": "1qagcgvij0k1011rms2pleptce",
    "oauth": {
        "domain": "bauth-dev.auth.ap-southeast-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "exp://127.0.0.1:19000/--/,exp://exp.host/@pretepop/tgilive/,tgilive://,http://localhost:3000/,https://a.tgi.live/,http://localhost:19006/,https://www.tgi.live/,exp://192.168.1.176:19000/",
        "redirectSignOut": "exp://127.0.0.1:19000/--/,exp://exp.host/@pretepop/tgilive/,tgilive://,http://localhost:3000/,https://a.tgi.live/,http://localhost:19006/,https://www.tgi.live/,exp://192.168.1.176:19000/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_AND_IDENTITY_POOLS",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://4ltww9nkx2.execute-api.ap-southeast-1.amazonaws.com/dev",
            "region": "ap-southeast-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://xycu7a5cubgdvlnppx5uepveam.appsync-api.ap-southeast-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-southeast-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-oaqfkfmewncttov7ydwh3gw5eu",
    "aws_user_files_s3_bucket": "bstorage94809-dev",
    "aws_user_files_s3_bucket_region": "ap-southeast-1"
};


export default awsmobile;

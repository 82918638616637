import React, { useRef } from 'react';
import {
  StyleSheet,
  Dimensions,
  SafeAreaView,
  FlatList,
  Platform,
} from 'react-native';
import { Block, Text, theme } from 'galio-framework';
import { useScrollToTop } from '@react-navigation/native';

import { Card } from '../components';
import { useFbPages } from '../hooks/useGraphQL';
// import articles from '../constants/articles';
import { HeaderHeight, isMobile } from '../constants/utils';
const { width } = Dimensions.get('window');

const MemoizedCard = React.memo(Card);

const Home = () => {
  const scrollRef = useRef(null);
  useScrollToTop(scrollRef);

  const { data, loading, loaded, fetch, refetch } = useFbPages();

  const rowData = [...Array(Math.ceil(data.length / 2)).keys()].map((index) => [
    data[index * 2],
    index * 2 + 1 < data.length ? data[index * 2 + 1] : undefined,
  ]);

  const renderItem = ({ item: row }) => (
    <Block flex row middle>
      {row.map((item, index) => (
        <MemoizedCard
          key={item?.id || 'blank'}
          item={{
            id: item.id,
            image: item.image,
            title: item.description,
            name: item.name,
          }}
          style={
            index === 1
              ? { marginLeft: theme.SIZES.BASE, marginRight: theme.SIZES.BASE }
              : { marginLeft: theme.SIZES.BASE }
          }
        />
      ))}
    </Block>
  );

  const onEndReached = () => {
    fetch();
  };

  const onRefresh = () => {
    refetch();
  };

  const renderArticles = () => {
    return (
      <SafeAreaView style={styles.articles}>
        {loaded && !data.length && (
          <Block flex center style={{ marginTop: 20, marginBottom: 20 }}>
            <Text>No videos found!</Text>
          </Block>
        )}
        <FlatList
          ref={scrollRef}
          data={rowData}
          renderItem={renderItem}
          keyExtractor={(row) =>
            `${row[0] && row[0].id}#${row[1] && row[1].id}`
          }
          onEndReached={onEndReached}
          onRefresh={onRefresh}
          refreshing={loading}
          showsVerticalScrollIndicator={!isMobile()}
        />
      </SafeAreaView>
    );
  };

  return (
    <Block flex center style={styles.home}>
      {renderArticles()}
    </Block>
  );
};

const styles = StyleSheet.create({
  home: {
    // width: width,
    width: '100%',
    // marginTop: Platform.OS === 'android' ? -HeaderHeight : 0,
  },
  articles: {
    // width: width - theme.SIZES.BASE * 2,
    width: '100%',
    paddingVertical: theme.SIZES.BASE,
    flex: 1,
  },
});

export default Home;

import React, { useEffect, useState } from 'react';
import { TextInput } from 'react-native';
import { Block, Text, theme } from 'galio-framework';
import { useFormspark } from '@formspark/use-formspark';

import { storeString, getString } from '../lib/storage';
import { argonTheme } from '../constants';
import Button from './Button';
import Modal from './Modal';
import CloseButton from './CloseButton';

const FORMSPARK_FORM_ID = 'cRJvYAOr';

export default () => {
  const [visible, setVisible] = useState(false);
  const [submit, submitting] = useFormspark({
    formId: FORMSPARK_FORM_ID,
  });
  const [message, setMessage] = useState('');
  const [email, setEmail] = useState('');
  useEffect(() => {
    (async function () {
      const popupScrollSubmitted = await getString('popupScrollSubmitted');
      if (popupScrollSubmitted !== '1') {
        setVisible(true);
        // await storeString('popupScrollSubmitted', '1');
      }
    })();
  }, []);

  const onSubmit = async () => {
    // Perform validation
    if (!message) {
      alert('Please provide a message about your experience!');
      return;
    }
    if (!email) {
      alert('Please provide your email address!');
      return;
    }
    if (
      !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        email
      )
    ) {
      alert('You have entered an invalid email address!');
      return;
    }

    await submit({ email, message });
    await storeString('popupScrollSubmitted', '1');
    alert('We have received your submission!');
    setVisible(false);
  };

  return (
    <Modal animationType="slide" transparent={true} visible={visible}>
      <Block style={styles.content}>
        <Block row middle style={{ marginTop: theme.SIZES.BASE }}>
          <Block middle style={{ flex: 1, maxWidth: 275 }}>
            <Text style={styles.headerText}>
              Discovered your next favourite live?
            </Text>
          </Block>
        </Block>
        <Block row middle style={{ marginTop: theme.SIZES.BASE }}>
          <Block middle style={{ flex: 1, maxWidth: 275 }}>
            <Text style={styles.bodyText}>How was your experience?</Text>
            <Text style={styles.bodyText}>
              Tell us how we're faring &amp; get a mystery gift!
            </Text>
          </Block>
        </Block>
        <Block row middle style={{ marginTop: theme.SIZES.BASE }}>
          <Block middle style={{ flex: 1, maxWidth: 300 }}>
            <TextInput
              style={styles.textInputs}
              value={message}
              placeholder="Message"
              placeholderTextColor={argonTheme.COLORS.MUTED}
              onChangeText={(text) => setMessage(text)}
              onSubmitEditing={onSubmit}
            />
            <TextInput
              style={styles.textInputs}
              value={email}
              placeholder="Email Address"
              placeholderTextColor={argonTheme.COLORS.MUTED}
              onChangeText={(text) => setEmail(text)}
              onSubmitEditing={onSubmit}
            />
          </Block>
        </Block>
        <Block row middle>
          <Button
            style={styles.buttons}
            onPress={onSubmit}
            disabled={submitting}
          >
            <Block row>
              <Text style={styles.textButtons}>Get Gift</Text>
            </Block>
          </Button>
        </Block>
      </Block>
      <CloseButton onPress={() => setVisible(false)} />
    </Modal>
  );
};

const styles = {
  content: {
    padding: 0,
    justifyContent: 'center',
  },
  headerText: {
    color: argonTheme.COLORS.PRIMARY,
    fontWeight: '900',
    fontSize: 28,
    textAlign: 'center',
  },
  bodyText: {
    color: argonTheme.COLORS.DEFAULT,
    fontWeight: '800',
    fontSize: 16,
    textAlign: 'center',
  },
  textInputs: {
    width: '100%',
    height: 30,
    padding: 5,
    borderColor: argonTheme.COLORS.DEFAULT,
    borderWidth: 3,
    borderRadius: 15,
    marginBottom: 5,
    // outline: 'none',
  },
  buttons: {
    width: 175,
    height: 40,
    backgroundColor: '#fff',
    shadowColor: argonTheme.COLORS.BLACK,
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowRadius: 8,
    shadowOpacity: 0.1,
    elevation: 1,
    // outline: 'none',
  },
  textButtons: {
    color: argonTheme.COLORS.PRIMARY,
    fontWeight: '600',
    fontSize: 14,
  },
};

import { Platform } from 'react-native';
import Constants from 'expo-constants';

const title = (text) =>
  Platform.select({
    web: `${text} | ${Constants.manifest.name}`,
    default: text,
  });

export default title;

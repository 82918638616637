import React from 'react';
import {
  Easing,
  Animated,
  Dimensions,
  Platform,
  useWindowDimensions,
} from 'react-native';
import { Ionicons } from '@expo/vector-icons';

import { createStackNavigator } from '@react-navigation/stack';
import { createDrawerNavigator } from '@react-navigation/drawer';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';

// import { Block } from "galio-framework";
import { useAmplifyUser } from '../lib/amplify';
import { useCategories } from '../hooks/useGraphQL';

// screens
import Home from '../screens/Home';
import Explore from '../screens/Explore';
import Brand from '../screens/Brand';
import ContactUs from '../screens/ContactUs';
// import Onboarding from "../screens/Onboarding";
import Livestream from '../screens/Livestream';
// import Pro from "../screens/Pro";
import Profile from '../screens/Profile';
// import Register from "../screens/Register";
// import Elements from "../screens/Elements";
// import Articles from "../screens/Articles";
import Login from '../screens/Login';
// drawer
import CustomDrawerContent from './Menu';

// header for screens
import { Icon, Header } from '../components';
import { argonTheme, tabs } from '../constants';
import { isMobile } from '../constants/utils';
import title from '../lib/title';

const { width } = Dimensions.get('screen');

const Stack = createStackNavigator();
const Drawer = createDrawerNavigator();
const Tab = createBottomTabNavigator();

// function ElementsStack(props) {
//   return (
//     <Stack.Navigator mode="card" headerMode="screen">
//       <Stack.Screen
//         name="Elements"
//         component={Elements}
//         options={{
//           header: ({ navigation, scene }) => (
//             <Header title="Elements" navigation={navigation} scene={scene} />
//           ),
//           cardStyle: { backgroundColor: "#F8F9FE" },
//         }}
//       />
//       <Stack.Screen
//         name="Pro"
//         component={Pro}
//         options={{
//           header: ({ navigation, scene }) => (
//             <Header
//               title=""
//               back
//               white
//               transparent
//               navigation={navigation}
//               scene={scene}
//             />
//           ),
//           headerTransparent: true,
//         }}
//       />
//     </Stack.Navigator>
//   );
// }

// function ArticlesStack(props) {
//   return (
//     <Stack.Navigator mode="card" headerMode="screen">
//       <Stack.Screen
//         name="Articles"
//         component={Articles}
//         options={{
//           header: ({ navigation, scene }) => (
//             <Header title="Articles" navigation={navigation} scene={scene} />
//           ),
//           cardStyle: { backgroundColor: "#F8F9FE" },
//         }}
//       />
//       <Stack.Screen
//         name="Pro"
//         component={Pro}
//         options={{
//           header: ({ navigation, scene }) => (
//             <Header
//               title=""
//               back
//               white
//               transparent
//               navigation={navigation}
//               scene={scene}
//             />
//           ),
//           headerTransparent: true,
//         }}
//       />
//     </Stack.Navigator>
//   );
// }

function ProfileStack(props) {
  return (
    <Stack.Navigator mode="card" headerMode="screen">
      <Stack.Screen
        name="Profile"
        component={Profile}
        options={{
          header: ({ navigation, scene }) => (
            <Header
              transparent
              white
              title="Profile"
              navigation={navigation}
              scene={scene}
            />
          ),
          cardStyle: { backgroundColor: '#FFFFFF' },
          headerTransparent: true,
        }}
      />
      {/* <Stack.Screen
        name="Pro"
        component={Pro}
        options={{
          header: ({ navigation, scene }) => (
            <Header
              title=""
              back
              white
              transparent
              navigation={navigation}
              scene={scene}
            />
          ),
          headerTransparent: true,
        }}
      /> */}
    </Stack.Navigator>
  );
}

function HomeHomeStack(props) {
  return (
    <Stack.Navigator mode="card" headerMode="screen">
      <Stack.Screen
        name={Platform.select({
          web: 'HomeStack',
          default: 'HomeTabs',
        })}
        component={Platform.select({
          web: HomeStack,
          default: HomeTabs,
        })}
        options={{
          headerShown: false,
          cardStyle: { backgroundColor: '#F8F9FE' },
        }}
      />
    </Stack.Navigator>
  );
}

function HomeStack(props) {
  const { data: categories } = useCategories();
  const combinedTabs = [...tabs.categories, ...categories];
  return (
    <Stack.Navigator mode="card" headerMode="screen">
      <Stack.Screen
        name="Home"
        component={Home}
        options={{
          title: title('Home'),
          header: ({ navigation, scene }) => {
            return (
              <Header
                title="Home"
                // search
                // options
                tabs={combinedTabs}
                tabIndex={combinedTabs[1].id}
                navigation={navigation}
                scene={scene}
              />
            );
          },
          cardStyle: { backgroundColor: '#F8F9FE', maxHeight: '100%' },
        }}
      />
    </Stack.Navigator>
  );
}

function ExploreStack(props) {
  return (
    <Stack.Navigator mode="card" headerMode="screen">
      <Stack.Screen
        name="Explore"
        component={Explore}
        options={{
          title: title('Explore'),
          header: ({ navigation, scene }) => (
            <Header title="Explore" navigation={navigation} scene={scene} />
          ),
          cardStyle: { backgroundColor: '#F8F9FE', maxHeight: '100%' },
        }}
      />
      <Stack.Screen
        name="Brand"
        component={Brand}
        options={({ route }) => ({
          title: title(route?.params?.fbPageName || 'Brand'),
          header: ({ navigation, scene }) => (
            <Header
              title={scene?.route?.params?.fbPageName || 'Brand'}
              back
              backTo="/explore"
              navigation={navigation}
              scene={scene}
            />
          ),
          cardStyle: { backgroundColor: '#F8F9FE', maxHeight: '100%' },
        })}
      />
    </Stack.Navigator>
  );
}

function ContactUsStack(props) {
  return (
    <Stack.Navigator mode="card" headerMode="screen">
      <Stack.Screen
        name="ContactUs"
        component={ContactUs}
        options={{
          title: title('Contact Us'),
          header: ({ navigation, scene }) => (
            <Header title="Contact Us" navigation={navigation} scene={scene} />
          ),
          cardStyle: { backgroundColor: '#F8F9FE' },
        }}
      />
    </Stack.Navigator>
  );
}

function HomeTabs() {
  return (
    <Tab.Navigator
      style={{ flex: 1 }}
      screenOptions={({ route }) => ({
        tabBarIcon: ({ focused, color, size }) => {
          let iconName;

          if (route.name === 'HomeStack') {
            iconName = focused ? 'ios-home' : 'ios-home-outline';
          } else if (route.name === 'ExploreStack') {
            iconName = focused ? 'ios-search' : 'ios-search-outline';
          } else if (route.name === 'Profile') {
            iconName = focused ? 'person-circle' : 'person-circle-outline';
          }

          return <Ionicons name={iconName} size={size} color={color} />;
        },
      })}
      tabBarOptions={{
        activeTintColor: argonTheme.COLORS.PRIMARY,
        inactiveTintColor: argonTheme.COLORS.MUTED,
        labelStyle: {
          fontWeight: 'bold',
        },
      }}
    >
      <Tab.Screen
        name="HomeStack"
        component={HomeStack}
        options={{ title: 'Home' }}
      />
      <Tab.Screen
        name="ExploreStack"
        component={ExploreStack}
        options={{ title: 'Explore' }}
      />
      {/* <Tab.Screen name="ProfileStack" component={ProfileStack} /> */}
      {/* <Tab.Screen name="ContactUsStack" component={ContactUsStack} /> */}
    </Tab.Navigator>
  );
}

function AppStack(props) {
  const dimensions = useWindowDimensions();

  return (
    <Drawer.Navigator
      style={{ flex: 1 }}
      drawerType={dimensions.width > 768 ? 'permanent' : 'front'}
      drawerContent={(props) => <CustomDrawerContent {...props} />}
      drawerStyle={{
        backgroundColor: 'white',
        width: dimensions.width < 400 ? width * 0.8 : 320,
      }}
      drawerContentOptions={{
        activeTintcolor: 'white',
        inactiveTintColor: '#000',
        activeBackgroundColor: 'transparent',
        itemStyle: {
          width: dimensions.width < 400 ? width * 0.8 : 300,
          backgroundColor: 'transparent',
          paddingVertical: 16,
          paddingHorizonal: 12,
          justifyContent: 'center',
          alignContent: 'center',
          alignItems: 'center',
          overflow: 'hidden',
        },
        labelStyle: {
          fontSize: 18,
          marginLeft: 12,
          fontWeight: 'normal',
        },
      }}
    >
      <Drawer.Screen name="HomeHomeStack" component={HomeHomeStack} />
      {!isMobile() && (
        <Drawer.Screen name="ExploreStack" component={ExploreStack} />
      )}
      {/* <Drawer.Screen name="ProfileStack" component={ProfileStack} /> */}
      <Drawer.Screen name="ContactUsStack" component={ContactUsStack} />
    </Drawer.Navigator>
  );
}

function RootStack() {
  return (
    <Stack.Navigator mode="modal" headerMode="none">
      <Stack.Screen name="App" component={AppStack} />
      <Stack.Screen
        name="Livestream"
        component={Livestream}
        options={({ route }) => ({
          title: title(route?.params?.fbVideoName || 'Livestream'),
        })}
      />
    </Stack.Navigator>
  );
}

export default function OnboardingStack() {
  const user = useAmplifyUser();
  return (
    <Stack.Navigator mode="card" headerMode="none">
      {isMobile() && !user ? (
        <Stack.Screen
          name="Login"
          component={Login}
          option={{
            headerTransparent: true,
          }}
        />
      ) : (
        <Stack.Screen name="Root" component={RootStack} />
        // <Stack.Screen name="App" component={AppStack} />
        // <>
        //   <Stack.Screen
        //     name="Onboarding"
        //     component={Onboarding}
        //     option={{
        //       headerTransparent: true,
        //     }}
        //   />
        // </>
      )}
    </Stack.Navigator>
  );
}

import AsyncStorage from '@react-native-async-storage/async-storage';

const PREFIX = 'tgilive_';

export const storeObject = async (key, value) => {
  try {
    const jsonValue = JSON.stringify(value);
    await AsyncStorage.setItem(`${PREFIX}${key}`, jsonValue);
  } catch (e) {
    // saving error
  }
};

export const storeString = async (key, value) => {
  try {
    await AsyncStorage.setItem(`${PREFIX}${key}`, value);
  } catch (e) {
    // saving error
  }
};

export const getObject = async (key) => {
  try {
    const jsonValue = await AsyncStorage.getItem(`${PREFIX}${key}`);
    return jsonValue != null ? JSON.parse(jsonValue) : null;
  } catch (e) {
    // error reading value
  }
};

export const getString = async (key) => {
  try {
    const value = await AsyncStorage.getItem(`${PREFIX}${key}`);
    if (value !== null) {
      return value;
    }
  } catch (e) {
    // error reading value
  }
};

import React from 'react';
import { Modal, StyleSheet, View } from 'react-native';
import { theme } from 'galio-framework';

export default ({ children, ...rest }) => {
  return (
    <View style={[styles.centeredView, styles.fullscreen, rest.visible && styles.overlay]}>
      <Modal {...rest}>
        <View style={styles.centeredView}>
          <View style={styles.modalView}>{children}</View>
        </View>
      </Modal>
    </View>
  );
};

const styles = StyleSheet.create({
  overlay: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)'
  },
  fullscreen: {
    flex: 0,
  },
  centeredView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  modal: {
    // borderWidth: 0,
    // alignSelf: 'center',
  },
  modalView: {
    margin: theme.SIZES.BASE,
    backgroundColor: 'white',
    borderRadius: theme.SIZES.BASE,
    padding: theme.SIZES.BASE,
    alignItems: 'center',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
  },
});

import React from 'react';
// import { useSafeArea } from "react-native-safe-area-context";
import {
  ScrollView,
  StyleSheet,
  Image,
  Platform,
  TouchableOpacity,
} from 'react-native';
import { Block, Text, theme } from 'galio-framework';
import { useLinkProps } from '@react-navigation/native';

import Images from '../constants/Images';
import { DrawerItem as DrawerCustomItem } from '../components';

import { useAmplifyUser } from '../lib/amplify';
import { isMobile } from '../constants/utils';

function CustomDrawerContent({
  drawerPosition,
  navigation,
  profile,
  focused,
  state,
  ...rest
}) {
  const user = useAmplifyUser();
  // const insets = useSafeArea();
  const screens = [
    // 'Home',
    // 'Explore',
    // "Profile",
    // "Account",
    // "Elements",
    // "Articles",
    // 'Contact Us',
  ];
  screens.push('Home');
  if (!isMobile()) {
    screens.push('Explore');
  }
  screens.push('Contact Us');
  if (user) {
    screens.push('Log Out');
  }

  const linkProps = useLinkProps({ to: '/home' });

  return (
    <Block
      style={styles.container}
      forceInset={{ top: 'always', horizontal: 'never' }}
    >
      <Block flex={0.06} style={styles.header}>
        <TouchableOpacity {...linkProps}>
          <Image style={styles.logo} source={Images.Logo} />
        </TouchableOpacity>
      </Block>
      <Block flex style={{ paddingLeft: 8, paddingRight: 14 }}>
        <ScrollView style={{ flex: 1 }} showsVerticalScrollIndicator={false}>
          {screens.map((item, index) => {
            return (
              <DrawerCustomItem
                title={item}
                key={index}
                navigation={navigation}
                focused={state.index === index ? true : false}
              />
            );
          })}
          {/* <Block
            flex
            style={{ marginTop: 24, marginVertical: 8, paddingHorizontal: 8 }}
          >
            <Block
              style={{
                borderColor: "rgba(0,0,0,0.2)",
                width: "100%",
                borderWidth: StyleSheet.hairlineWidth,
              }}
            />
            <Text color="#8898AA" style={{ marginTop: 16, marginLeft: 8 }}>
              DOCUMENTATION
            </Text>
          </Block> */}
          {/* <DrawerCustomItem title="Getting Started" navigation={navigation} /> */}
        </ScrollView>
      </Block>
      <Block flex={0.06} style={styles.header}>
        <Text color="#8898AA" style={{ marginBottom: 16, marginLeft: 8 }}>
          PretePop © {new Date().getFullYear()}
        </Text>
      </Block>
    </Block>
  );
}

const styles = {
  container: {
    flex: 1,
  },
  header: {
    paddingHorizontal: 28,
    paddingBottom: theme.SIZES.BASE,
    paddingTop: theme.SIZES.BASE * 3,
    justifyContent: 'center',
  },
  logo: {
    ...Platform.select({
      web: {
        width: 100,
        height: 50,
      },
    }),
  },
};

export default CustomDrawerContent;

import { canUseDOM } from 'fbjs/lib/ExecutionEnvironment';
import invariant from 'fbjs/lib/invariant';

const initialURL = canUseDOM ? window.location.href : '';

const Linking = {
  addEventListener: () => {},
  removeEventListener: () => {},
  canOpenURL: () => {
    return Promise.resolve(true);
  },
  getInitialURL: () => {
    return Promise.resolve(initialURL);
  },
  openURL: (url, target = '_self') => {
    try {
      open(url, target);
      return Promise.resolve();
    } catch (e) {
      return Promise.reject(e);
    }
  },
  _validateURL: (url) => {
    invariant(
      typeof url === 'string',
      'Invalid URL: should be a string. Was: ' + url
    );
    invariant(url, 'Invalid URL: cannot be empty');
  },
};

const open = (url, target) => {
  if (canUseDOM) {
    window.open(url, target);
  }
};

export default Linking;

import React, { useState, useRef } from 'react';
import { Image, Platform } from 'react-native';
import AppLoading from 'expo-app-loading';
import { useFonts } from '@use-expo/font';
import { Asset } from 'expo-asset';
import * as WebBrowser from 'expo-web-browser';
import * as Linking from 'expo-linking';
import { Ionicons } from '@expo/vector-icons';
import * as Analytics from 'expo-firebase-analytics';
import { Block, GalioProvider } from 'galio-framework';
import { NavigationContainer } from '@react-navigation/native';

// Before rendering any navigation stack
import { enableScreens } from 'react-native-screens';
enableScreens();

import Screens from './navigation/Screens';
import linking from './navigation/linking';
import { PopupStart } from './components';
import { Images, articles, argonTheme } from './constants';
import { isMobile } from './constants/utils';
import { AmplifyAuthProvider } from './lib/amplify';
import { useCategories } from './hooks/useGraphQL';

import Amplify from 'aws-amplify';
import awsconfig from './aws-exports';

const domain = 'auth.tgi.live';

async function urlOpener(url, redirectUrl) {
  const { type, url: newUrl } = await WebBrowser.openAuthSessionAsync(
    url,
    redirectUrl
  );

  if (type === 'success' && Platform.OS === 'ios') {
    await WebBrowser.dismissBrowser();
    return Linking.openURL(newUrl);
  }
}

const expoScheme = 'tgilive://';
let redirectUrl;
try {
  // Expo RN app
  redirectUrl = Linking.makeUrl();
} catch (e) {
  // RN app
  redirectUrl = expoScheme;
}
if (redirectUrl.startsWith('exp://1')) {
  // handle simulator(localhost) and device(Lan)
  redirectUrl = redirectUrl + '/--/';
} else if (redirectUrl === expoScheme) {
  // dont do anything
} else {
  // handle the expo client
  redirectUrl = redirectUrl + '/';
}

Amplify.configure({
  ...awsconfig,
  oauth: {
    ...awsconfig.oauth,
    domain,
    urlOpener: isMobile() ? urlOpener : undefined,
    redirectSignIn: redirectUrl,
    redirectSignOut: redirectUrl,
  },
});

// cache app images
const assetImages = [
  Images.Onboarding,
  Images.LogoOnboarding,
  Images.Logo,
  Images.LoginBackground,
  Images.Pro,
  Images.ArgonLogo,
  Images.iOSLogo,
  Images.androidLogo,
  Images.Video,
  Images.Facebook,
  Images.Messenger,
  Images.Whatsapp,
  Images.Avatar,
];

// cache product images
articles.map((article) => assetImages.push(article.image));

function cacheImages(images) {
  return images.map((image) => {
    if (typeof image === 'string') {
      return Image.prefetch(image);
    } else {
      return Asset.fromModule(image).downloadAsync();
    }
  });
}

export default () => {
  const routeNameRef = useRef();
  const navigationRef = useRef();

  const [isLoadingComplete, setLoading] = useState(false);
  let [fontsLoaded] = useFonts({
    ArgonExtra: require('./assets/font/argon.ttf'),
    Galio: require('galio-framework/src/fonts/galio.ttf'),
    ...Ionicons.font,
  });
  const { loaded: categoriesLoaded } = useCategories();

  function _loadResourcesAsync() {
    return Promise.all([...cacheImages(assetImages)]);
  }

  function _handleLoadingError(error) {
    // In this case, you might want to report the error to your error
    // reporting service, for example Sentry
    console.warn(error);
  }

  function _handleFinishLoading() {
    setLoading(true);
  }

  if (!categoriesLoaded || !fontsLoaded || !isLoadingComplete) {
    return (
      <AppLoading
        startAsync={_loadResourcesAsync}
        onError={_handleLoadingError}
        onFinish={_handleFinishLoading}
      />
    );
  } else {
    return (
      <AmplifyAuthProvider>
        <NavigationContainer
          ref={navigationRef}
          linking={linking}
          onReady={() =>
            (routeNameRef.current = navigationRef.current.getCurrentRoute().name)
          }
          onStateChange={() => {
            const previousRouteName = routeNameRef.current;
            const currentRouteName = navigationRef.current.getCurrentRoute()
              .name;

            if (previousRouteName !== currentRouteName) {
              Analytics.setCurrentScreen(currentRouteName);
            }

            // Save the current route name for later comparision
            routeNameRef.current = currentRouteName;
          }}
        >
          <GalioProvider theme={argonTheme}>
            <Block flex>
              <Screens />
              {!isMobile() && <PopupStart />}
            </Block>
          </GalioProvider>
        </NavigationContainer>
      </AmplifyAuthProvider>
    );
  }
};

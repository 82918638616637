export default [
  {
    title: 'Ice cream is made with carrageenan …',
    image: 'https://images.unsplash.com/photo-1516559828984-fb3b99548b21?ixlib=rb-1.2.1&auto=format&fit=crop&w=2100&q=80',
    cta: 'View article', 
    horizontal: true
  },
  {
    title: 'Is makeup one of your daily esse …',
    image: 'https://images.unsplash.com/photo-1519368358672-25b03afee3bf?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2004&q=80',
    cta: 'View article'
  },
  {
    title: 'Coffee is more than just a drink: It’s …',
    image: 'https://images.unsplash.com/photo-1500522144261-ea64433bbe27?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2102&q=80',
    cta: 'View article' 
  },
  {
    title: 'Fashion is a popular style, especially in …',
    image: 'https://images.unsplash.com/photo-1487222477894-8943e31ef7b2?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1326&q=80',
    cta: 'View article' 
  },
  {
    title: 'Argon is a great free UI packag …',
    image: 'https://images.unsplash.com/photo-1482686115713-0fbcaced6e28?fit=crop&w=1947&q=80',
    cta: 'View article', 
    horizontal: true
  },
];
import React, { useEffect, useState, useRef } from 'react';
import { View } from 'react-native';
import shaka from 'shaka-player';

function iOS() {
  return (
    [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod',
    ].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  );
}

export default (props) => {
  // Creating reference to store video component on DOM
  const videoComponentRef = useRef();
  const [player, setPlayer] = useState();

  // Link to MPEG-DASH video
  const manifestUri =
    props.live && iOS()
      ? props.videoProps?.source?.hlsUri
      : props.videoProps?.source?.uri;

  function onErrorEvent(event) {
    // Extract the shaka.util.Error object from the event.
    onError(event.detail);
  }

  function onError(error) {
    // Log the error.
    console.error('Error code', error.code, 'object', error);
  }

  useEffect(() => {
    // Reference to our video component on DOM
    const video = videoComponentRef.current;

    // Initialize shaka player
    const player = new shaka.Player(video);
    // playerRef.current = player;
    setPlayer(player);

    // Listen for error events.
    player.addEventListener('error', onErrorEvent);
  }, []);

  useEffect(() => {
    if (player) {
      // Try to load a manifest.
      // This is an asynchronous process.
      player
        .load(manifestUri)
        .then(function () {
          // This runs if the asynchronous load is successful.
          // console.log('The video has now been loaded!');
        })
        .catch(onError); // onError is executed if the asynchronous load fails.
    }
  }, [player, manifestUri]);

  /*
    Returning video with a container which is required to load shaka player UI.
  */
  return (
    <View style={props?.style || {}}>
      <div
        className="video-container"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          width: '100%',
          height: '100%',
        }}
      >
        <video
          className="shaka-video"
          style={{
            maxWidth: '100%',
            maxHeight: '100%',
          }}
          ref={videoComponentRef}
          controls
          autoPlay
          playsInline
        />
      </div>
    </View>
  );
};

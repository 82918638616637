import { useEffect } from 'react';
import Constants from 'expo-constants';

export default (text) => {
  useEffect(() => {
    if (text) {
      document
        .querySelector('meta[name="description"]')
        .setAttribute('content', text);

      return () => {
        if (text) {
          document
            .querySelector('meta[name="description"]')
            .setAttribute('content', Constants.manifest.description);
        }
      };
    }
  }, [text]);
};

import React, { useState, useEffect } from 'react';
import {
  StyleSheet,
  Dimensions,
  Image,
  TouchableHighlight,
  Linking,
} from 'react-native';
import { Block, Text, theme } from 'galio-framework';
import { API, graphqlOperation } from 'aws-amplify';

import { Card } from '../components';
import argonTheme from '../constants/Theme';
import Images from '../constants/Images';
const { width } = Dimensions.get('screen');

const ContactUs = () => {
  return (
    <Block flex center style={styles.contactUs}>
      <Block flex row style={{ paddingVertical: 16, paddingHorizontal: 16 }}>
        <TouchableHighlight
          style={{ height: 60 }}
          onPress={async () =>
            Linking.openURL('https://m.me/tgiLiveSingapore')
              .then((data) => {
                console.log('Messenger opened successfully ' + data);
              })
              .catch(() => {
                alert(
                  'Oops, you need to have Messenger installed on your device'
                );
              })
          }
        >
          <Image
            source={Images.Messenger}
            style={{ height: 64, width: 64, margin: 8 }}
          />
        </TouchableHighlight>
        {/* <TouchableHighlight
          style={{ height: 60 }}
          onPress={async () =>
            Linking.openURL('whatsapp://send?text=Help&phone=6598165501')
              .then((data) => {
                console.log('WhatsApp opened successfully ' + data);
              })
              .catch(() => {
                alert(
                  'Oops, you need to have WhatsApp installed on your device'
                );
              })
          }
        >
          <Image
            source={Images.Whatsapp}
            style={{ height: 64, width: 64, margin: 8 }}
          />
        </TouchableHighlight> */}
      </Block>
    </Block>
  );
};

const styles = StyleSheet.create({
  contactUs: {
    width: width,
  },
});

export default ContactUs;
